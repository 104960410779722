window.chkScroll = function (elem) {
	var obj = $(elem);
  if($(obj).scrollTop() + $(obj).innerHeight() >= $(obj)[0].scrollHeight) {
  	var nextButton = $(elem).parent().find('.next')[0];
  	$(nextButton).removeClass('custom_disable');
  }
}

window.toggleWhoIsApplyingFields = function(){
	var val = $(this).val();
	if(val == 'other'){
		$('#who_is_applying_other_fields').show();
	}else{
		$('#who_is_applying_other_fields').hide();
	}
}

window.toggleNestedQuestions = function(ids){
	$('.boolean_question_choice').each(function(i, obj) {
    var checked = $(this).prop('checked');
    var val = parseInt($(this).val());
    if (checked && ids.includes(val)){
    	$('#' + val + '_choices').show();
    }
	});
}

window.initDateInputMask = function(elementId){
	$(document).on('click, focus', elementId, function () {
		$(elementId).inputmask({
	    alias: "datetime",
  		inputFormat: 'mm/dd/yyyy',
			placeholder: "mm/dd/yyyy"
		});
  });
}

window.toggleDrugsOtherFields = function(){
  $('.quantity-select').each(function(i, obj) {
    toggleOtherQuantity($(obj));
  });

  $('.frequency-select').each(function(i, obj) {
    toggleOtherFrequency($(obj));
  });
}

window.toggleOtherQuantity = function(obj){
  var val = $(obj).val();
  var elem = $(obj).parent().find('.other_quantity');
  if (val == 'other_quantity'){
    $(elem).show();
    $(elem).attr('disabled', false);
  }
  else{
    $(elem).hide();
    $(elem).attr('disabled', true);
  }
}

window.toggleOtherFrequency = function(obj){
  var elem = $(obj).parent().find('.other_frequency');
  var val = $(obj).val();
  if (val == 'other_frequency'){
    $(elem).show();
    $(elem).attr('disabled', false);
  }
  else{
    $(elem).hide();
    $(elem).attr('disabled', true);
  }
}

$(document).ready(function(){
  $('body').on('click', '#send_soa_link_button', function(){
    var Id = $(this).data('id');
    confirmModal("Are you sure want to send SOA link to applicant?", function(){
      $.get('/applications/'+Id+"/send_soa_link",function(){});
      infoModal("SOA link sent to applicant");
    });
  });
});