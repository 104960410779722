$(document).ready(function (argument) {

  $('body').on('click', '#drug_search_reset_btn', function(){
    $('#drugs_search_autocomplete').val('').trigger('change');
  });

  $('body').on('click', '#drug_search_cancel_btn', function(){
    $('#all_drugs_search_autocomplete').val('');
    $(this).hide();
  });

});


window.drugSearchAutoComplete = function(){
  const autoCompleteJS = new autoComplete({
    selector: "#drugs_search_autocomplete",
    placeHolder: "Search for Drugs...",
    data: {
      src: async (query) => {
        try {
          var planId = $('#plan_id').val();
          var planClass = $('#plan_class').val();
          const source = await fetch(`/plans/drug_search_autocomplete?plan_id=${planId}&plan_class=${planClass}`);
          const data = await source.json();
          return data;
        } catch (error) {
          return error;
        }
      }
    },
    resultItem: {
      highlight: true
    },
    events: {
      input: {
        selection: (event) => {
          const selection = event.detail.selection.value;
          autoCompleteJS.input.value = selection;
          var form = document.querySelector('#drug_search_form');
          form.dispatchEvent(new Event('submit', {bubbles: true}));
        }
      }
    }
  });
}

window.allDrugsSearchAutoComplete = function(){
  const autoCompleteJS = new autoComplete({
    selector: "#all_drugs_search_autocomplete",
    placeHolder: "Search for Drugs...",
    data: {
      src: async (query) => {
        try {
          const source = await fetch(`/plans/all_drugs_search`);
          const data = await source.json();
          return data;
        } catch (error) {
          return error;
        }
      },
      keys: ["name"]
    },
    resultsList: {
      element: (list, data) => {
        const info = document.createElement("p");
        if (data.results.length > 0) {
          info.innerHTML = `Displaying <strong>${data.results.length}</strong> out of <strong>${data.matches.length}</strong> results`;
          $('#drug_search_cancel_btn').show();
        } else {
          info.innerHTML = `Found <strong>${data.matches.length}</strong> matching results for <strong>"${data.query}"</strong>`;
        }
        list.prepend(info);
      },
      noResults: true,
      maxResults: 10000,
      tabSelect: true
    },
    resultItem: {
      highlight: true
    },
    events: {
      input: {
        selection: (event) => {
          const selection = event.detail.selection.value.name;
          autoCompleteJS.input.value = selection;
          var form = document.querySelector('#drug_details_form');
          $('#drug_id').val(event.detail.selection.value.id);
          form.dispatchEvent(new Event('submit', {bubbles: true}));
        }
      }
    }
  });
}