$(document).ready(function(){
  
  initializeTalkToSpecialistDatePicker();

  initializeTalkToSpecialistTimePicker();

});

window.initializeTalkToSpecialistDatePicker = function(){
  $('#mw_appointment_date').datepicker({
    format: 'mm/dd/yyyy',
    autoclose: true, 
    todayHighlight: true
  });
}

window.initializeTalkToSpecialistTimePicker = function(){
  $('#talk-to-specialist-timepicker').timepicker({
    timeFormat: 'h:mm p',
    interval: 60,
    // minTime: '10',
    // maxTime: '6:00pm',
    // defaultTime: '11',
    // startTime: '10:00',
    dynamic: false,
    dropdown: true,
    scrollbar: true,
    zindex: 9999999
  });
}