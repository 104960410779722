window.initInputMask = function (){
  // Input Mask
  if ($.isFunction($.fn.inputmask)) {
      $("[data-mask]").each(function(i, el) {
          var $this = $(el),
              mask = $this.data('mask').toString(),
              opts = {
                  numericInput: getValue($this, 'numeric', false),
                  radixPoint: getValue($this, 'radixPoint', ''),
                  rightAlign: getValue($this, 'numericAlign', 'left') == 'right'
              },
              placeholder = getValue($this, 'placeholder', ''),
              is_regex = getValue($this, 'isRegex', '');

          if (placeholder.length) {
              opts[placeholder] = placeholder;
          }


          if (mask.toLowerCase() == "phone") {
              mask = "(999) 999-9999";
          }

          if (mask.toLowerCase() == "email") {
              mask = 'Regex';
              opts.regex = "[a-zA-Z0-9._%-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,4}";
          }

          if (mask.toLowerCase() == "fdecimal") {
              mask = 'decimal';
              $.extend(opts, {
                  autoGroup: true,
                  groupSize: 3,
                  radixPoint: getValue($this, 'rad', '.'),
                  groupSeparator: getValue($this, 'dec', ',')
              });
          }


          if (mask.toLowerCase() == "currency" || mask.toLowerCase() == "rcurrency") {

              var sign = getValue($this, 'sign', '$');;

              mask = "999,999,999.99";
              if (mask.toLowerCase() == 'rcurrency') {
                  mask += ' ' + sign;
              } else {
                  mask = sign + ' ' + mask;
              }

              opts.numericInput = true;
              opts.rightAlignNumerics = false;
              opts.radixPoint = '.';
          }

          if (is_regex) {
              opts.regex = mask;
              mask = 'Regex';
          }

          $this.inputmask(mask, opts);
      });
  }

  /*---------------------------------*/

  // autoNumeric
  if ($.isFunction($.fn.autoNumeric)) {
      $('.autoNumeric').autoNumeric('init');
  }
}

window.getValue = function($el, data_var, default_val) {
    if (typeof $el.data(data_var) != 'undefined') {
        return $el.data(data_var);
    }
    return default_val;
}

window.formatPhoneNumber = function(element) {
    console.log("called");
    var mno = $(element).val()
    var mno_new = (""+mno).replace(/\D/g, '');
    var new_mno = mno_new.match(/^(\d{3})(\d{3})(\d{4})$/);
    new_mno = (!new_mno) ? null : "(" + new_mno[1] + ") " + new_mno[2] + "-" + new_mno[3];
    element.value = new_mno;
}

window.showBlockUI = function(){
  jQuery.blockUI({ message: '<h1><img src="/images/loader.gif" /> Please wait...</h1>', baseZ: 999999 });
}

window.hideBlockUI = function(){
  jQuery.unblockUI();
}

window.infoModal = function(message, okCallback) {
  $('#infoModalTitle').html(message);
  $('#infoModal').modal();
  $('#btnok').html("OK");
  $('#btnok').off('click');
  $('#btnok').click(function() {
  $('#info_modal_alert_title h3').html("Alert");
    if(okCallback)
      okCallback();
  });
}

window.confirmModal = function(message, yesCallback, noCallback) {
  $('#confirmModalTitle').html(message);
  $('#confirmModal').modal();
  $('#btnYes').off('click');
  $('#btnYes').click(function() {
    if(yesCallback)
      yesCallback();
  });
  $('#btnNo').off('click');
  $('#btnNo').click(function() {
    if(noCallback)
      noCallback();
  });
}

window.infoMessage = function(message){
  var html = `<div class="alert alert-info" style="font-size: 14px;">
                <i class="fas fa-info" style="margin-right: 5px;"></i>
                ${message}
              </div>`;
  html;
}


window.ULTRA_SETTINGS = window.ULTRA_SETTINGS || {};

/*--------------------------------
     Tooltips & Popovers
 --------------------------------*/
ULTRA_SETTINGS.tooltipsPopovers = function() {

    $('[data-toggle="tooltip"]').each(function() {
        var animate = $(this).attr("data-animate");
        var colorclass = $(this).attr("data-color-class");
        $(this).tooltip({
            template: '<div class="tooltip ' + animate + ' ' + colorclass + '"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
        });
    });

    $('[data-toggle="popover"]').each(function() {
        var animate = $(this).attr("data-animate");
        var colorclass = $(this).attr("data-color-class");
        $(this).popover({
            template: '<div  role="tooltip" class="popover ' + animate + ' ' + colorclass + '"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
        });
    });
};


/*--------------------------------
     Other Form component Scripts
 --------------------------------*/
ULTRA_SETTINGS.otherScripts = function() {
  initInputMask();
}

ULTRA_SETTINGS.tooltipsPopovers();


jQuery("body").on("click", ".logout_class", function(){
  $.get('/dashboards/logout_modal',function(data){
      $('#myModal').html(data);
      $('#myModal').modal();
    });
  return false;
});

window.formatToRailsDate = function(date){
  var dateComponents = date.split("/");
  if(dateComponents.length == 3){
    return `${dateComponents[2]}/${dateComponents[0]}/${dateComponents[1]}`;
  }
  else{
    return '';
  }
}

$(document).ready(function(){

  $('.mobile-btn').click(function(){
      $('.nav-mobile').css("width", "100%");
  });
  $('.x-btn').click(function(){
      $('.nav-mobile').css("width", "0");
  });

  $('.scroll-to-top').click(function(){
    $("html, body").animate({ scrollTop: 0 }, 600);
    return false;
  });

  $('.scroll-to-fourpart').click(function(){
    $("html, body").animate({ scrollTop: $('#fourpart').offset().top - 80}, 600);
    return false;
  });

  $('.js-scroll').click(function(e){

    
    var id = "#"+e.target.href.split("#")[1];

    $("html, body").animate({ scrollTop: $(id).offset().top - 80}, 600);


    return true;
  });

  $('body').on('focusin', '#booklet_subscriber_email', function(){
    $('#email-error').hide();
  });

  $('body').on('click', '#edit-mobile-number-link', function(){
    $('#update-mobile-form').show();
    $('.mobile-verification-element').hide();
  });

  $('body').on('click', '#mobile-number-update-btn', function(){
    var mobileNumber = $('#mobile_number').val();
    $.ajax({
      type: 'PATCH',
      url: '/update_mobile_number',
      data: {
        mobile_number: mobileNumber,
        id: $('#object_id').val(),
        class_name: $('#class_name').val(),
        resend_code_url: $('#resend_code_url').val(),
        show_resend_link: $('#show_resend_link').val(),
        source_page: $('#source_page').val()
      }
    });
  });

  $('body').on('click', '#mobile-number-update-cancel-btn',function (argument) {
    window.location.reload()
  })

  $('body').on('click', '#mw-email-update-cancel-btn',function (argument) {
    window.location.reload();
  });

  $('body').on('click', '#edit-email-address-link',function (argument) {
    $('#email-address-confirm-form').show();
    $('#email-address-confirm-link').hide();
  });

  $('body').on('click', '#mw-email-update-btn', function(){
    var email = $('#email').val();
    var emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    var isEmail = emailRegex.test(email);
    console.log(isEmail);
    if(isEmail){
      var id = $('#email_object_id').val();
      var redirectToAccountConfirmation =  $('#redirect_to_account_confirmation').val();
      $.ajax({
        type: 'PATCH',
        url: "/update_email_address",
        data: {
          email: email,
          id: id,
          source_object: $('#source_object').val()
        },

        success: function(){
          window.location = redirectToAccountConfirmation
        }
      });
    }
    else{
      $('#email-messages').html('<div class="alert alert-danger alert-dismissible fade show" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>Please enter valid email!</div>')
      window.setTimeout(function() { 
        $("#email-messages .alert").alert('close');
      }, 3000);
    }
  });

  $('body').on('click', '.already_registered_button', function(){
    var val = $(this).val();
    if(val == 'no'){
      window.location = $('#sign_up_url').val();
    }
    else{
      window.location = '/sign_in_via_mobile_or_email'+$('#parameters').val();
    }
  });

  $("body").on('click','.toggle-password', function() {

    // $(this).toggleClass("Show Hide");
    var $this = this
    var className = $(this).data('toggle-class');
    var passField = $(this).closest('.row-form').find('.'+className)
    if ($(passField).attr("type") == "password") {
      $(passField).attr("type", "text");
      // $(this).find('a').html("Hide");
      // setTimeout(function(){$(passField).attr("type", "password"); $($this).find('a').html("Show"); }, 5000);

    } else {
      $(passField).attr("type", "password");
      // $(this).find('a').html("Show");

    }
  });

  
  $('body').on('click', 'a[href="tel:1-800 381 0976"]', function(e){
    e.preventDefault();
    infoModal("Please call as on below number: <h5 style='margin-top: 10px;font-weight: bold;'><span class='primary-color'>1-888 694 6964</span></h5>");
  });
  

});

window.bookletSubscriberModal = function() {
  $.get('/booklet_subscriber_modal',function(data){
  $('#myModal').html(data);
  $('#myModal .modal-header').remove();
  $('#myModal .modal-footer').remove();
  $('#myModal .modal-content').css('border-radius', '0px');
  $('#myModal .modal-backdrop').css('background', 'transparent');
  $('#myModal').css('top', '90px');
  $('#myModal').modal();
  initInputMask();
  sessionStorage['show_booklet'] = 'false'
  });
  return false;
}
