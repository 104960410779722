$(document).ready(function(){

  $(document).on('change', '.plan-select-checkbox', function(event){
  	validateSelectedPlans(event);
  });

  $(document).on('click', '#plans-compare-btn', function(event){
  	var status = validateSelectedPlans(event, false);
    var controller = $(this).data('controller');
    var planSearchId = $('#plans-compare-btn').data('plan-search-id');
    if(status == true){
      $('#compare-plan-form').remove();
  		$('<form>', {
        "id": "compare-plan-form",
        "html": planCompareFormHtml(),
        "action": `/${controller}/${planSearchId}/compare_plans`,
        "method": "GET",
        "style": "display: none;"
      }).appendTo(document.body).submit();
  	}
  });

  $(document).on('submit', '#save_comparison_report_form', function(event){
    showBlockUI();
  });

  $(document).on('click', '#modal_login_btn', function(event){
    var planSearchId = $(this).data('plan-search-id');
    var planId = $(this).data('plan-id');
    var planType = $(this).data('plan-type');
    $('#start_enrollment_wrapper').hide();
    var url = "plan_search_id="+planSearchId+"&plan_id="+planId+"&plan_type="+planType;
    $.get("/users/login_modal?"+url,function(data){
      $('.modal-title h3').html("Sign In");
      $('#myModal .modal-dialog').css('min-width', '25%');
      $('#login_form_wrapper').html(data.html);
    });
  });

  $('#zipcode_select2').select2({
    ajax: {
      url: '/ma_plans/list_zipcodes',
      dataType: 'json',
    },
    minimumInputLength: 3,
    formatInputTooShort: "Please add more text"
  });

  $(document).on('click', '#hide-compare-btn', function(){
    hideCompareBtn();
    $('.plan-select-checkbox:checked').prop('checked', false);
  });

  $('body').on('click', '.basic-plans-btn', function(){
    var planSearchId = $(this).attr('plan-search-id');
    var planType = $(this).attr('plan-type');
    var type = $(this).attr('type');
    $.get($('#already_registered_modal_url').val(),function(data){});
    // window.location = `/sign_up?plan_search_id=${planSearchId}&source=fast_track_medicare&score=0&plan_type=${planType}&type=${type}&basic_details_source=fast_track_medicare`;
  });

  $('body').on('click', '.drugs-modal-link', function(){
    var controller = $(this).data('controller');
    var page = $(this).data('page');
    var planSearchId = $(this).data('plan-search-id');
    $.get(`/${controller}/${planSearchId}/my_drugs/add_drugs`,function(data){});
  });
  
});

window.getSelectedPlans = function(){
  return $('.plan-select-checkbox:checked');
}

window.validateSelectedPlans = function(event, show_message=true){
	var selectedPlans = getSelectedPlans();
	var status = true;
	if ($(selectedPlans).length > 3){
		if(show_message == true){
      $('#myModal .modal-body').css('height', 'auto');
		  infoModal("Max 3 products can be selected for comparison.");
		  if($(event.target).hasClass('plan-select-checkbox')){
		  	$(event.target).prop('checked', false);
		  }
      showCompareBtn();
		}
		status = false;
	}
	else{
		if($(selectedPlans).length >= 2){
			$('#plans-compare-btn').removeClass('custom_disable');
      showCompareBtn();
    }
  	else{
  		$('#plans-compare-btn').addClass('custom_disable');
      hideCompareBtn()
      status = false;
  	}
  }
  return status;
}

function planCompareFormHtml(){
  var selectedPlans = getSelectedPlans();
  var planSearchId = $('#plans-compare-btn').data('plan-search-id');
  var planType = $('#plan_type_like').val();
  var html = ``;
  $(selectedPlans).each(function(i, obj) {
    var id = $(obj).val();
    html += `<input type="text" name="selected_plans[]" value="${id}" />`;
  });
  html += `<input type="text" name="plan_type_like" value="${planType}" />`;
  return html;
}

function showCompareBtn(){
  $('#compare-mobile-btn-wrapper').css('top', $('.navbar').height()+5);
  $('#compare-mobile-btn-wrapper').slideDown(500);
}

function hideCompareBtn(){
  $('#compare-mobile-btn-wrapper').hide();
}

window.hidePlansData = function(source, index, row, planSearchId, planType, planLike, hideRowIndex){
  if(source == "fast_track_medicare"){
    if(index >= hideRowIndex){
      $(row).css({'filter': 'blur(3px)', 'color': 'gray'});
      $(row).addClass('basic-plans-btn');
      $(row).attr('plan-search-id', planSearchId);
      $(row).attr('plan-type', planType);
      $(row).attr('type', planLike);
      $('td:eq(0)', row).html('<i class="fas fa-plus-circle"></i>');
      for(i=1; i < $(row).find('td').length; i++){
        $(`td:eq(${i})`, row).html( 'xxxxxxx' );
      }
    }
  }
}
