$(document).ready(function(){

  initDateInputMask('#mw_appointment_date');

//   $('body').on('change', '#mw_appointment_date', function(){
//     var date = $(this).val();
//     if(date != ''){
//       queryParams = "?appointment_date="+formatToRailsDate($(this).val());
//       queryParams += "&field_changed=appointment_date"
//       $.get("/get_time_selections"+queryParams, function( data ) {
//         $('#time_selections').html(data.html);
//       });  
//     }else{
//       resetTimeSelections(['#mw_start_hour', '#mw_start_minute', '#mw_end_hour', '#mw_end_minute']);
//     }
//   });

//   $('body').on('change', '#mw_start_hour', function(){
//     var startHour = $(this).val();
//     if(startHour != ''){
//       queryParams = "?appointment_date="+formatToRailsDate($('#mw_appointment_date').val());
//       queryParams += "&start_hour="+startHour;
//       queryParams += "&field_changed=start_hour"
//       $.get("/get_time_selections"+queryParams, function( data ) {
//         $('#time_selections').html(data.html);
//       });  
//     }else{
//       resetTimeSelections(['#mw_start_minute', '#mw_end_hour', '#mw_end_minute']);
//     }
//   });

//   $('body').on('change', '#mw_start_minute', function(){
//     var startHour = $('#mw_start_hour').val();
//     var startMinute = $(this).val();
//     if(startHour != '' && startMinute != ''){
//       queryParams = "?appointment_date="+formatToRailsDate($('#mw_appointment_date').val());
//       queryParams += "&start_hour="+startHour;
//       queryParams += "&start_minute="+startMinute;
//       queryParams += "&field_changed=start_minute"
//       $.get("/get_time_selections"+queryParams, function( data ) {
//         $('#time_selections').html(data.html);
//       });  
//     }
//     else{
//       resetTimeSelections(['#mw_end_hour', '#mw_end_minute']);
//     }
//   });

//   $('body').on('change', '#mw_end_hour', function(){
//     var startHour = $('#mw_start_hour').val();
//     var startMinute = $('#mw_start_minute').val();
//     var endHour = $(this).val();
//     if(startHour != '' && startMinute != '' && endHour != ''){
//       queryParams = "?appointment_date="+formatToRailsDate($('#mw_appointment_date').val());
//       queryParams += "&start_hour="+startHour;
//       queryParams += "&start_minute="+startMinute;
//       queryParams += "&end_hour="+endHour;
//       queryParams += "&field_changed=end_hour"
//       $.get("/get_time_selections"+queryParams, function( data ) {
//         $('#time_selections').html(data.html);
//       });  
//     }else{
//       resetTimeSelections(['#mw_end_minute']);
//     }
//   });

});

// window.resetTimeSelections = function(elements){
//   $.each(elements, function( index, value ) {
//     $(value).find('option').not(':first').remove();
//   });
// }