$(document).ready(function(){

	$(document).on('click', '.show-app-details', function(evt){
    evt.preventDefault();
    evt.stopImmediatePropagation();
    var id = $(this).data('id');
    $.get("/applications/"+id,function(data){
      $('.wb-tab').removeClass('active');
      $('#show_application_tab').addClass('active').removeClass('custom_disable');
      $('#applications_listing_div').hide();
      $('#show_application_div').html(data);
      $('#show_application_div').show();
    });
  });

  $(document).on('click', '.client_request_details_btn', function(evt){
    evt.preventDefault();
    evt.stopImmediatePropagation();
    var id = $(this).data('id');
    $.get("/client_requests/"+id,function(data){
      $('.wb-tab').removeClass('active');
      $('#show_client_requests_details_tab').addClass('active').removeClass('custom_disable');
      $('#show_client_requests_listing_div').hide();
      $('#show_client_requests_details_div').html(data);
      $('#show_client_requests_details_div').show();
    });
  });

  $(document).on('click', '.show-soa-form-btn', function(evt){
    evt.preventDefault();
    evt.stopImmediatePropagation();
    var id = $(this).data('id');
    $.get("/applications/"+id+"/view_soa_form",function(data){
      $('#show_soa_form_div').html(data.html);
      $('#show_soa_form_tab').trigger('click');
      initDateInputMask('#scope_of_appointment_appointment_completion_date');
      $('#show_soa_form_div').show();
    });
  });

  $(document).on('click', '.application-scope', function(){
    $('.application-scope').removeClass('active');
    $(this).addClass('active');
    $('#application-scope').val($(this).attr('id'));
    table.draw();
  });

  $('body').on('click','.assign_to_agent',function(evt){
    evt.preventDefault();
    var Id = $(this).data('id');
    $.get("/applications/assign_to_agent_modal?id="+Id,function(data){
      $('#myModal').html(data);
      $('#myModal').modal();
    });
	});

	$('.wb-tab').on('click',function(evt){
    evt.preventDefault();
    evt.stopImmediatePropagation();
    $('.wb-tab-content').hide();
    var divId = $(this).data('content_id');
    var currentObj = this;
    var ulP  = $(this).closest('ul');
    $(ulP).children('.wb-tab').removeClass('active');
    $(this).addClass('active');
    $('#'+divId).show();
    if(divId == 'applications_listing_div'){
      $('.details-tabs').removeClass('active').addClass('custom_disable');
      $('#show_application_tab').removeClass('active').addClass('custom_disable');
    }
    else if(divId == 'show_client_requests_listing_div'){
      $('#show_client_requests_details_tab').removeClass('active').addClass('custom_disable');
      showBlockUI();
      $.get("/client_requests/client_requests_listing",function(data){
        $('#show_client_requests_listing_div').html(data);
        hideBlockUI();
      });
    }
  });

  $('body').on('click', '#reminder_email_send_btn', function(e){
    e.preventDefault();
    e.stopImmediatePropagation();
    showBlockUI();
    $(this).addClass('custom_disable');
    var emailContent = $('#email_content_div').html();
    $('#email_content').val(emailContent);
    $('#reminder_email_submit_btn').click();
  });


});