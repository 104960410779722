window.initializeDrugSelect2 = function(elem){
	$(elem).select2({
	  ajax: {
	    url: '/ma_plans/list_drugs',
	    dataType: 'json',
	    delay: 250,
	    data: function (params) {
	      return {
	        q: params.term,
	        page: params.page
	      };
	    },
	    processResults: function (data, params) {
	      params.page = params.page || 1;
	      // debugger
	      return {
	        results: data.results,
	        pagination: {
	          more: (params.page * 30) < data.total_count
	        }
	      };
	    },
	    cache: true
	  },
	  placeholder: 'Rx Drugs (enter drug name)',
	  minimumInputLength: 1,
	  templateResult: formatDrug,
	  templateSelection: formatDrugSelection,

	}).on('change',function(){
		$('#add-drugs-btn').removeAttr('disabled');
	});
}

function formatDrug (drug) {
	if (drug.loading) {
    return drug.text;
  }
  return drug.text;
}

function formatDrugSelection (drug) {
  return drug.text;
}

function toggleDetailsIcon(thisIcon, controllerName){
	var iconHtml = $(thisIcon).html();
	var id = $(thisIcon).data('id');
	if(iconHtml.includes('plus')){
		$(thisIcon).html('<i class="fas fa-minus-circle"></i>');
		$(thisIcon).attr('title', $(thisIcon).data('minus-title'));
		showDrugDetails(thisIcon, id, controllerName);
	}
	else{
		$(thisIcon).html('<i class="fas fa-plus-circle"></i>');
		$(thisIcon).attr('title', $(thisIcon).data('plus-title'));
		$('#details_'+id).remove();
	}
}

function showDrugDetails(thisObj, id, controllerName){
	var planId = $(thisObj).data('plan-search-id');
	$.get("/"+controllerName+"/"+id+"/searched_drugs_details?page="+controllerName+"&plan_search_id="+planId,function(data){

    var drugs = data["drugs"];
    var notCovered = data["not_covered_drugs"];

    var tableName = (controllerName == 'ma_plans') ? 'ma_plan_table' : 'partd_plan_table';

		var tableColumnSize = $(`#${tableName} thead tr th`).length;
		liHtml = '';
		drugs.forEach(function(entry) {
		  liHtml += '<li class="d-inline"> <i class="fa fa-check"> </i>'+entry+'</li>';
		});

    notCovered.forEach(function(entry) {
      liHtml += '<li class="d-inline"> <i class="fa fa-times"></i>'+entry+'</li>';
    });

    var html = `<tr id=details_${id} class=drug-details-row>
      <td colspan=${tableColumnSize}>
      <ul class="list-unstyled drug-details">${liHtml}</ul>`;

    $('#'+id).after(html);

  });
}

window.getSelectedCheckboxIds = function(){
	var ids = []
	$(".selected_drugs_checkbox:checked").each(function(){
	  ids.push($(this).val());
	});
	return ids
}

$('body').on('click', '.details_icon', function(e){
	e.preventDefault();
	e.stopImmediatePropagation();
	var controllerName = $(this).data('controller');
	toggleDetailsIcon($(this), controllerName);
});