$(document).ready(function(){
	$(".copy-to-clipboard-personal-url").click(function(event){
		var $tempElement = $("<input>");
    $("body").append($tempElement);
    $tempElement.val($('#url-text').text()).select();
    document.execCommand("Copy");
    $tempElement.remove();
    $('.copy-to-clipboard-personal-url').attr('data-original-title', 'Copied');
    $(this).tooltip('show');
  });

  $(".copy-to-clipboard-personal-url").click(function(event){
  	$(".copy-to-clipboard-personal-url").attr('data-original-title', '');
  });

});