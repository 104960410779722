var current_fs, next_fs, previous_fs;
var left, opacity, scale;
var animating;

window.multiFormNext = function(current_fs, next_fs, step) {
  $('#error-div').html("");
  // if (animating) return false;
  // animating = true;
  $('#' + step + '_li').addClass("active");
  current_fs.hide();
  next_fs.show();
  // current_fs.animate({
  //   opacity: 0
  // }, 
  // {
  //   step: function(now, mx) {
  //     scale = 1 - (1 - now) * 0.2;
  //     left = (now * 50) + "%";
  //     opacity = 1 - now;
  //     current_fs.css({
  //       'transform': 'scale(' + scale + ')'
  //     });
  //     next_fs.css({
  //       'left': left,
  //       'opacity': opacity
  //     });
  //   },
  //   duration: 800,
  //   complete: function() {
  //     current_fs.hide();
  //     animating = false;
  //   },
  //   easing: 'easeInOutExpo'
  // });
};

window.multiFormPrevious = function(current_fs, previous_fs, step) {
  $('#error-div').html("");
  // if (animating) return false;
  // animating = true;
  $('#' + step + '_li').addClass("active");
  current_fs.hide();
  previous_fs.show();
  // current_fs.animate({
  //   opacity: 0
  // }, 
  // {
  //   step: function(now, mx) {
  //     scale = 0.8 + (1 - now) * 0.2;
  //     left = ((1 - now) * 50) + "%";
  //     opacity = 1 - now;
  //     current_fs.css({
  //       'left': left
  //     });
  //     previous_fs.css({
  //       'transform': 'scale(' + scale + ')',
  //       'opacity': opacity
  //     });
  //   },
  //   duration: 800,
  //   complete: function() {
  //     current_fs.hide();
  //     animating = false;
  //   },
  //   easing: 'easeInOutExpo'
  // });
};